<template>
    <b-container :class="[isMobile ? 'mt-5 pt-3' : 'mt-3' ]">
        <div v-if="isLoading" class="d-flex flex-column justify-content-center overlay">
            <b-progress variant="success" striped :animated="true" class="w-50 mx-auto">
                <b-progress-bar :value="pdfProgressAmt">
                    <span>Loading - Progress: <strong>{{ pdfProgressAmt }}%</strong></span>
                </b-progress-bar>
            </b-progress>
        </div>

        <b-button v-if="!isMobile" variant="primary" @click="goBack">
            <font-awesome-icon icon="fa-chevron-left" /> Back
        </b-button> 
        <b-button variant="secondary" @click="print" class="ml-1">
            <font-awesome-icon icon="fa-print" />
        </b-button> 
        <b-button class="ml-1" variant="outline-dark" @click="download" >
            <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" />
        </b-button>
        <b-button class="ml-1 mr-2" variant="outline-dark" @click="email" >
            <font-awesome-icon icon="fa-regular fa-envelope" />
        </b-button>

        <br v-if="isMobile" />
        <div v-show="!isLoading" :class="[isMobile ? 'h6 d-inline align-middle': 'h5 d-inline align-middle']">
            File name: {{invoiceName}}
        </div>  

        <div>
            <vue-pdf-embed 
                :source="pdfSource" 
                @progress="pdfProgress" 
                @rendered="handleDocumentRender" 
                ref="pdfRef">
            </vue-pdf-embed>
        </div>
        
    </b-container>
</template>

<script>
import { format, getUnixTime } from 'date-fns'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import numbro from 'numbro'
import print from 'print-js'
import Browser from '@/config/browser.js'
import fileDownload from 'js-file-download'

export default {
    name: 'viewInvoice',
    components: {
        VuePdfEmbed
    },
    computed: {
        id() {
            return this.$route.params.id
        },
        userInfo() {
          return this.$state.userInfo
        },
        isMobile() {
            return this.$state.isMobile
        },
        printCount() {
            if(this.contract.printCount) {
                return this.contract.printCount
            } else {
                return 0
            }
        }
    },
    data() {
        return {
           contract: {},
           money: [],
           numPages: null,
           pdfSource: "",
           printSrc: null,
           invoiceName: "",
           emailed: false,
           printed: false,
           downloaded: false,
           isLoading: true,
           pdfProgressAmt: 0,
           pageCount: 1
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        pdfProgress(data) {
            //console.log(data)
            let fileSize = data.total
            let progress = data.loaded

            let percentComplete = (progress*100)/fileSize
            //console.log(`PercentComplete is: ${percentComplete}`)
            this.pdfProgressAmt = percentComplete
        },
        handleDocumentRender() {
            this.isLoading = false
            this.pageCount = this.$refs.pdfRef.pageCount
        },
        async print() {
            //if printed we will keep a copy and not delete pdf 
            this.printed = true
            //update the printCount for this job
            let payload = { printCount: this.printCount+1}
            try {
                const res = await this.$axios.put(`/job/${this.id}`, payload, {
                    headers: { Authorization: `Bearer ${this.$state.token}` },
                    responseType: 'blob'
                })
                console.log(res)
            } catch (err) {
                console.log("printCount Update Error", err)
            }

            //log that we printed this invoice.
            const newLog = {
                logTime: getUnixTime(new Date()),
                logMsg: `Invoice Printed`,
                addInfo: `{ invoiceName: ${this.invoiceName} }`,
                job: this.id,
                logUser: this.userInfo.name
            }

            this.$actions.log(newLog)

            if( Browser.isChrome() ){
                console.log(`Browser is NOT safari...pritn directly`)
                print(this.printSrc)
            } else {
                console.log(`Browser is safari...redirecting to new tab`)
                window.location.href = this.printSrc   
            }
            
        },
        async download() {
            this.downloaded = true
            let fileData = {
                name: this.invoiceName,
                contractId: this.contract.contractId,
                type: "invoice"
            }

            try {
                const res = await this.$axios.put(`/download`, fileData, {
                    headers: { Authorization: `Bearer ${this.$state.token}` },
                    responseType: 'blob'
                })
                console.log(res)
                fileDownload(res.data, fileData.name)

            } catch (err) {
                console.log("Downlaod Error", err)
            }
        },
        async email() {
            //route to email page with the info needed to create a link to the file.
            const file = this.invoiceName
            const contractId = this.contract.contractId
            this.emailed = true

            this.$router.push(
                {
                    name: 'email', 
                    params: { 
                        customer : this.contract.customer._id, 
                        job: this.contract._id, 
                        email: this.contract.customer.emails.primary, 
                        cc:  this.contract.customer.emails.secondary,
                        fname: this.contract.customer.fname,
                        invoiceLink: {
                            file: file,
                            contractId: contractId
                        }
                    } 
                }
            )

        },
        async makeTemplete() {
            console.log(`makeTemplete Called...id is: ${this.$route.params.id}`)

            try {
                const res = await this.$axios.get(`/job/${this.id}`, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })

                const moneyRes = await this.$axios.get(`/money/job/${this.id}`, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })
                //formatDates
                let invoiceDate = format(new Date(), "MM/dd/yyyy")
                res.data.dates.invoiceDate = invoiceDate
                this.contract = res.data
                
                //add up money and get some totals
                let money = moneyRes.data
                
                console.log("Money is:")
                console.log(money)

                const invoiceDebits = money.filter(lineItem => lineItem.debit.toLowerCase() === 'debit')  
                const invoiceTotal = invoiceDebits.reduce((sum, lineItem) => {
                    let newSum = sum + lineItem.amount
                    return newSum
                }, 0)
                console.log(`Invoice total is: ${invoiceTotal}`)

                const invoiceSubTotal = invoiceDebits.reduce((sum, lineItem) => {
                    let newSum = sum + lineItem.sales.gutters + lineItem.sales.permits + lineItem.sales.roofing + lineItem.sales.siding + lineItem.sales.windows
                    return newSum
                }, 0)
                console.log(`Invoice total is: ${invoiceSubTotal}`)

                const invoiceTax = invoiceDebits.reduce((sum, lineItem) => {
                    let newSum = sum + lineItem.sales.tax
                    return newSum
                }, 0)
                console.log(`Invoice total is: ${invoiceTax}`)

                const invoicePayments = money.filter(lineItem => lineItem.debit.toLowerCase() === 'credit')
                const invoiceTotalPayments = invoicePayments.reduce((sum, lineItem) => {
                    let newSum = sum + lineItem.amount
                    return newSum
                },0)
                console.log(`Total Payments are: ${invoiceTotalPayments}`)

                const balance = invoiceTotal - invoiceTotalPayments
                console.log(`invoice balance is: ${balance}`)
                
                money.forEach(lineItem => {
                    if(lineItem.debit.toLowerCase() != 'debit') {
                        //we do this for the mustache template on the server side
                        lineItem.debit = null
                    }
                    if(lineItem.sales.tax > 0) {
                        //this is so we can display the lineItem without the tax
                        //again the mustache template uses this on the server side
                        lineItem.amount = lineItem.amount - lineItem.sales.tax
                    }
                    lineItem.amount = numbro(lineItem.amount).format()
                })

                const invoiceMoney = {
                    moneyItems: money,
                    total: numbro(invoiceTotal).format(),
                    subTotal: numbro(invoiceSubTotal).format(),
                    tax: numbro(invoiceTax).format(),
                    payments: numbro(invoiceTotalPayments).format(),
                    balance: numbro(balance).format()
                }                
                console.log(`Contract data loaded.`)
                this.money = invoiceMoney

                this.genPDF(this.contract, this.money, this.contract.contractId)

            } catch (err) {
                console.error(err)
            }
        },
        async genPDF(contract, money, contractId){
            console.log(`genPDF Called...`)
            
            let payload = {
                contractId: contractId,
                money: money,
                contract: contract
            }

            try {
                const res = await this.$axios.post(`/pdf/invoice`, payload, {
                        headers: { Authorization: `Bearer ${this.$state.token}`,
                        responseType: "blob" 
                    }
                })
                
                console.log(res)
                console.log(`GenPDF returned`)
                
                if(res.status == 200){

                    let filePath = res.data.filename
                    this.invoiceName = filePath.substring(filePath.lastIndexOf('/')+1)
                    this.pdfSource = `${this.$baseUrl}/files/${contractId}/invoices/${this.invoiceName}`
                    this.printSrc = `${this.$baseUrl}/files/${contractId}/invoices/${this.invoiceName}`
                    
                    //logit
                    let log = {
                        logTime: getUnixTime(new Date()),
                        logMsg: `Invoice was viewed: ${this.invoiceName}`,
                        job: this.id,
                        logUser: this.userInfo.name,
                        addInfo: `File path: ${res.data.filename}`
                    }
                    this.$actions.log(log)

                    /*
                    this.src = pdf.createLoadingTask(`${this.$baseUrl}/files/${contractId}/invoices/${this.invoiceName}`)
                    this.printSrc = `${this.$baseUrl}/files/${this.contract.contractId}/invoices/${this.invoiceName}`

                    this.src.then(pdf => {
                        this.numPages = pdf.numPages
                    })

                    //logit
                    let log = {
                        logTime: getUnixTime(new Date()),
                        logMsg: `Invoice was viewed: ${this.invoiceName}`,
                        job: this.id,
                        logUser: this.userInfo.name,
                        addInfo: `File path: ${res.data.filename}`
                    }
                    this.$actions.log(log)
                    */
                } else {
                    console.err("Something went wrong...")
                }

            } catch (err) {
                console.error(err)
            }
        }
    },
    created() {
        numbro.setDefaults({
            thousandSeparated: true,
            mantissa: 2
        })

        this.makeTemplete()
    },
    async beforeDestroy(){
        //was file printed, emailed or downloaded? if not you can delete this file
        if(this.emailed || this.downloaded || this.printed) {
            console.log ("Invoice was used for email, print, or download. Saving file.")
        } else {

            let payload = {
                contractId: this.contract.contractId,
                filename: this.invoiceName,
                path: "invoices"
            }
            console.log("This invoice was never used. Delete file.")
            
            try {
                const res = await this.$axios.post(`/pdf/delete`, payload, {
                        headers: { Authorization: `Bearer ${this.$state.token}` 
                    }
                })
                console.log(res)

            } catch (err) {
                console.error(err)
            }
            
        }
    }
}
</script>

<style lang="scss" scoped>
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    cursor: pointer;
    z-index: 2;
}
.vue-pdf-embed {
    margin-bottom: 8px;
    margin-top:2em;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

</style>